"use client"

import type { ButtonHTMLAttributes } from "react"
import clsx from "clsx"

import type { TPanelMaterials } from "~/components/ui/Collection/CollectionDefault/components/PanelMaterials/_data/serializer"
import { PanelMaterialsComponent } from "~/components/ui/Collection/CollectionDefault/components/PanelMaterials/components"
import { Link, type AbstractLinkProps } from "~/components/ui/Link"
import Icon, { type IconName } from "~/components/abstracts/Icon"
import { Spinner } from "~/components/abstracts/Spinner"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type RoundedCtaProps = AbstractLinkProps &
  css.RoundedCtaVariants & {
    displayIcon?: boolean
    iconName?: IconName
    iconClassName?: string
    htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"]
    asDiv?: boolean
    materialPanels?: TPanelMaterials
  }

function RoundedCta({
  className,
  children,
  type,
  weight,
  size,
  htmlType = "button",
  theme,
  width,
  opacityBorder,
  displayIcon = false,
  iconName = "Eye",
  iconClassName,
  disabled,
  notAllowed,
  isLoading,
  target,
  materialPanels,
  asDiv = false,
  ...rest
}: RoundedCtaProps) {
  const { add } = usePanel()

  if (!children && !displayIcon) {
    return null
  }

  function triggerPopin() {
    if (!materialPanels) return null
    add(<PanelMaterialsComponent materialsItems={materialPanels} />)
  }

  const isLink = !!rest?.href

  const CtaChildren = (
    <>
      {children}
      {displayIcon ? (
        <Icon
          name={iconName}
          theme="inherit"
          className={clsx(css.icon, iconClassName, { [css.isOnlyIcon]: !children && displayIcon })}
          height="18"
          width="18"
        />
      ) : null}
    </>
  )

  const commonProps = {
    "data-comp": "UI/RoundedCta",
    className: clsx(
      className,
      css.RoundedCta({
        type,
        theme,
        width,
        weight,
        disabled,
        notAllowed,
        isLoading,
        size,
        opacityBorder,
      }),
      { [css.isDiv]: asDiv }
    ),
  }

  return asDiv ? (
    <div {...commonProps}>{CtaChildren}</div>
  ) : (
    <Link
      {...commonProps}
      {...rest}
      htmlType={isLink ? undefined : htmlType}
      target={target}
      onClick={materialPanels ? triggerPopin : rest?.onClick}
    >
      {isLoading && (
        <div className={css.spinner}>
          <Spinner size={16} color="currentColor" />
        </div>
      )}
      <span className={sprinkles({ opacity: isLoading ? 0 : 1, width: "100%" })}>{CtaChildren}</span>
    </Link>
  )
}

export default RoundedCta
